export const config = {
    serverRoute: "https://test-prueba-production.up.railway.app"
    //serverRoute: "http://localhost:3001"
 }
 
 export const SocketRoute = {
    serverRoute: "https://railway.grupo-hoteles.com"
    //serverRoute: "http://localhost:3001"
 }
 
 export const CLoubedsRoute ={
    serverRoute: "https://railway.grupo-hoteles.com"
    //serverRoute:"http://localhost:3001"
 }
 
 export const Environment ={
   propertyID:"315188",
   Token:"cbat_Kdh8Ze2fIiRN6PeBsk5i0M0iB2bkR5ey"
}
